import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
import JobTitle from "../components/job_title";
import ExternalWifi from "../components/external_wifi";
import HomepageWrapper from "../components/homepage-wrapper";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageWrapper;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Alberto Leal`}</h1>
    <JobTitle mdxType="JobTitle" />
    <br />
    <p><a parentName="p" {...{
        "href": "https://github.com/dashed"
      }}>{`github.com/dashed`}</a></p>
    <p><a parentName="p" {...{
        "href": "mailto:mailforalberto@gmail.com"
      }}>{`mailforalberto@gmail.com`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/dasheddashed"
      }}>{`twitter.com/dasheddashed`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.linkedin.com/in/hirealberto"
      }}>{`linkedin.com/in/hirealberto`}</a></p>
    <br />
    <hr></hr>
    <br />
    <h2>{`Portfolio`}</h2>
    <p>
  <Link to="/uprise" mdxType="Link">Uprise</Link>
    </p>
    <p>
  <Link to="/waves" mdxType="Link">Waves</Link>
    </p>
    <p>
  <Link to="/wrks" mdxType="Link">WRKS</Link>
    </p>
    <p>
  <Link to="/the-liddo-cupcakery" mdxType="Link">the liddo cupcakery</Link>
    </p>
    <br />
    <hr></hr>
    <br />
    <h2>{`Code`}</h2>
    <h3>{`Rust`}</h3>
    <p>
  <Link to="/code/advent-of-code-2018" mdxType="Link">Advent of Code 2018</Link>
    </p>
    <p>
  <Link to="/code/technical-indicators" mdxType="Link">Technical indicators</Link>
    </p>
    <p>
  <Link to="/code/grokdb2-flashcard-app" mdxType="Link">grokdb2 (flashcard app)</Link>
    </p>
    <p>
  <a href="https://github.com/dashed/rebalance-app">github.com/dashed/rebalance-app</a>
  <br />
  Optimal lazy portfolio rebalancing calculator (in Rust)
    </p>
    <p>
  <a href="https://github.com/dashed/yampa">github.com/dashed/yampa</a>
  <br />
  Yet another master password app (CLI) backed by a JSON file
    </p>
    <p>
  <a href="https://github.com/dashed/wakeup">github.com/dashed/wakeup</a>
  <br />
  Get time of when to wake up. (REM sleep cycle calculator)
    </p>
    <p>
  <a href="https://github.com/dashed/fromnow">github.com/dashed/fromnow</a>
  <br />
  Generate dates and times relative from now
    </p>
    <p>
  <a href="https://github.com/dashed/td-transactions-to-ledger">github.com/dashed/td-transactions-to-ledger</a>
  <br />
  Convert TD Bank transactions to ledger-cli compatible transactions
    </p>
    <p>
  <a href="https://github.com/dashed/tangerine-transactions-to-ledger">
    github.com/dashed/tangerine-transactions-to-ledger
  </a>
  <br />
  Convert Tangerine Bank transactions to ledger-cli compatible transactions.
    </p>
    <p>
  <a href="https://github.com/dashed/pg_query_parser">github.com/dashed/pg_query_parser</a>
  <br />
  PostgreSQL parser for Rust (work in progress)
    </p>
    <p>
  <a href="https://github.com/dashed/esparser">github.com/dashed/esparser</a>
  <br />
  JavaScript Parser in Rust (work in progress)
    </p>
    <h3>{`C`}</h3>
    <p>
  <Link to="/code/tetris" mdxType="Link">Tetris in C</Link>
    </p>
    <h3>{`golang`}</h3>
    <p>
  <a href="https://github.com/dashed/grokdb-golang">github.com/dashed/grokdb-golang</a>
  <br />
  Flashcard app in golang
    </p>
    <h3>{`JavaScript`}</h3>
    <p>
  <Link to="/code/npx-albertoleal" mdxType="Link">npx albertoleal</Link>
    </p>
    <p>
  <Link to="/code/iphone-wallpaper-generator" mdxType="Link">Personal iPhone Wallpaper generator</Link>
    </p>
    <p>
  <Link to="/code/gojs" mdxType="Link">gojs</Link>
    </p>
    <p>
  <a href="https://github.com/dashed/shallowequal">github.com/dashed/shallowequal</a>
  <br />
  Like lodash v3.x isEqualWith but for shallow equal. Over 6 million monthly downloads on npm. Popular dependency among
  React-based libraries such as react-dnd and Ant Design.
    </p>
    <p>
  <a href="https://github.com/dashed/react-resume">github.com/dashed/react-resume</a>
  <br />
  📄 Generate resumes using React, puppeteer, and styled-components.
    </p>
    <p>
  <a href="https://github.com/dashed/react-app-rewire-babel-loader">github.com/dashed/react-app-rewire-babel-loader</a>
  <br />
  Rewire babel-loader loader in your create-react-app project using react-app-rewired.
    </p>
    <p>
  <a href="https://github.com/dashed/react-offline">github.com/dashed/react-offline</a>
  <br />
  React component that notifies when browser is either offline or online.
    </p>
    <p>
  <a href="https://github.com/dashed/react-combokeys">github.com/dashed/react-combokeys</a>
  <br />
  Bind to keyboard shortcuts in React.
    </p>
    <p>
  <a href="https://github.com/dashed/react-hashchange">github.com/dashed/react-hashchange</a>
  <br />
  React component that notifies when browser's hash (i.e. `window.location.hash`) changes.
    </p>
    <p>
  <a href="https://github.com/dashed/dayofweek">github.com/dashed/dayofweek</a>
  <br />
  Port of dayofweek function given by Tomohiko Sakamoto on the comp.lang.c Usenet newsgroup.
    </p>
    <p>
  <a href="https://github.com/dashed/sizeof-loader">github.com/dashed/sizeof-loader</a>
  <br />
  Webpack loader that works like url-loader (or file-loader) but with extracted information such as image dimensions and
  file-size.
    </p>
    <p>
  <a href="https://github.com/dashed/logdna-demo">github.com/dashed/logdna-demo</a>
  <br />
  Coding challenge for <a href="https://logdna.com">LogDNA</a>. Converting an image to a React-based app. Demo at{" "}
  <a href="https://logdna-demo.netlify.com">logdna-demo.netlify.com</a>.
    </p>
    <p>
  <a href="https://github.com/dashed/mathjax-lazyload">github.com/dashed/mathjax-lazyload</a>
  <br />
  Delays loading of MathJax Tex elements. Demo at{" "}
  <a href="http://dashed.github.io/mathjax-lazyload">dashed.github.io/mathjax-lazyload</a>.
    </p>
    <br />
    <hr></hr>
    <br />
    <h2>{`Talks`}</h2>
    <h4>{`Bridge School (Cohort 7 — Sponsored by RBC) — April 2019`}</h4>
    <p>{`Presented a lightning talk on Chrome DevTools and React Developer Tools for `}<a parentName="p" {...{
        "href": "https://bridgeschool.io"
      }}>{`Bridge School`}</a>{`.`}</p>
    <p>{`Bridge School offers free software development and product design education for women, agender, and non-binary professionals in technology.`}</p>
    <p>{`Slides: `}<a parentName="p" {...{
        "href": "https://bridge-school-devtools.netlify.com"
      }}>{`bridge-school-devtools.netlify.com`}</a></p>
    <p>{`Source: `}<a parentName="p" {...{
        "href": "https://github.com/dashed/chrome-react-devtools-talk"
      }}>{`github.com/dashed/chrome-react-devtools-talk`}</a></p>
    <br />
    <hr></hr>
    <br />
    <p>{`If you have Node.js and npm with `}<a parentName="p" {...{
        "href": "https://blog.npmjs.org/post/162869356040/introducing-npx-an-npm-package-runner"
      }}>{`npx`}</a>{`, you can view my personal npm business card:`}</p>
    <pre><code parentName="pre" {...{}}>{`npx albertoleal
`}</code></pre>
    <p>{`Source code at `}<a parentName="p" {...{
        "href": "https://github.com/dashed/albertoleal"
      }}>{`github.com/dashed/albertoleal`}</a></p>
    <br />
    <hr></hr>
    <br />
    <p>{`FYI, some folks know me as just Albert; thus, I also own the domain `}<a parentName="p" {...{
        "href": "https://albertleal.ca"
      }}>{`albertleal.ca`}</a>{`, which just redirects to `}<a parentName="p" {...{
        "href": "https://albertoleal.ca"
      }}>{`albertoleal.ca`}</a>{`.`}</p>
    <hr></hr>
    <br />
    <ExternalWifi mdxType="ExternalWifi" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      